import React from "react";
import { Link } from "gatsby";
import { closeMenu, enableScrolling } from "../utils/menuHelpers";

export default function MenuModal() {
  const LinkStopPropogation = ({ to, children }) => {
    const handleClick = (e) => {
      e.stopPropagation();
      // Any additional logic can be added here
    };

    return (
      <Link to={to} onClick={handleClick}>
        {children}
      </Link>
    );
  };
  return (
    <>
      <div
        id="menuModalContents"
        className="hidden"
        // onMouseLeave={closeMenu}
      >
        {/* <div className="px-medium pt-medium pt-ml-small pb-medium pb-ml-small fs-small fs-ml-medium fs-mxl-small fs-mxxl-large fs-mxxxl-xl"> */}
        <div className="px-medium pb-medium pb-ml-small fs-small fs-ml-medium fs-mxl-small fs-mxxl-large fs-mxxxl-xl">
          <ul className="global-menu-contents">
            <li>
              {/* <h6 className="capsule-header">Type</h6> */}
              <ul className="global-menu-mounting">
                <li>
                  <LinkStopPropogation to="/range/ceiling/">
                    <span>Ceiling</span>
                  </LinkStopPropogation>
                  <ul className="global-menu-sub">
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/ceiling/recessed/">
                        <span>Recessed</span>
                      </LinkStopPropogation>
                    </li>
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/ceiling/surface/">
                        <span>Surface</span>
                      </LinkStopPropogation>
                    </li>
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/ceiling/suspended/">
                        <span>Suspended</span>
                      </LinkStopPropogation>
                    </li>
                  </ul>
                </li>
                <li>
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/magnetic-system/">
                    <span>Magnetic System</span>
                  </LinkStopPropogation>
                  <ul className="global-menu-sub">
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/magnetic-system/recessed/">
                        <span>Recessed</span>
                      </LinkStopPropogation>
                    </li>
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/magnetic-system/surface/">
                        <span>Surface</span>
                      </LinkStopPropogation>
                    </li>
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/magnetic-system/suspended/">
                        <span>Suspended</span>
                      </LinkStopPropogation>
                    </li>
                  </ul>
                </li>
                <li>
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/profile/">
                    <span>Linear Systems</span>
                  </LinkStopPropogation>
                </li>
                <li>
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/track/">
                    <span>Track</span>
                  </LinkStopPropogation>
                </li>
                <li>
                  {/* <LinkStopPropogation to="/range/wall/"> */}
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/wall/">
                    <span>Wall</span>
                  </LinkStopPropogation>
                </li>
                <li>
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/exterior/">
                    <span>Exterior</span>
                  </LinkStopPropogation>
                  <ul className="global-menu-sub">
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/exterior/recessed/">
                        <span>Recessed</span>
                      </LinkStopPropogation>
                    </li>
                    <li>
                      <hr className="" />
                      {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                      <LinkStopPropogation to="/range/exterior/surface/">
                        <span>Surface</span>
                      </LinkStopPropogation>
                    </li>
                  </ul>
                </li>
                <li>
                  <hr className="" />
                  {/* <hr className="my-small my-ms-medium my-ml-small" /> */}
                  <LinkStopPropogation to="/range/auxiliaries/">
                    <span>Auxiliaries</span>
                  </LinkStopPropogation>
                </li>
              </ul>
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <button
        id="menuModalBlocker"
        className="hidden"
        aria-label="Close Menu"
        type="button"
        onClick={closeMenu}
      >
        <span className="visually-hidden">Close Menu</span>
      </button>
    </>
  );
}
